<template>
  <section id="final-users">
    <b-row>
      <b-col>
        <h1>{{ $t('Profile') }}</h1>
      </b-col>
      <b-col>
        <div class="d-flex justify-content-end">
          <router-link to="/dashboard">
            <b-button variant="danger">
              {{ $t("dataGeneric.cancel") }}
            </b-button>
          </router-link>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h5 class="text-primary">
          {{ $t('editProfile') }}
        </h5>
      </b-col>
    </b-row>

    <b-card class="mt-5">
      <validation-observer ref="simpleRules">
        <b-form @input="changeForm()">
          <b-tabs v-if="introspectionData.username && userData">
            <b-tab active>
              <template #title>
                <feather-icon icon="UserIcon" />
                <span> {{ $t("User") }} </span>
              </template>
              <b-card-body>
                <b-card-title class="mt-1">
                  {{ $t("administrators.usIn") }}
                </b-card-title>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.username.description
                      "
                      :label="$t('administrators.user')"
                      label-for="mc-user-name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="User Name"
                        rules="required"
                      >
                        <b-form-input
                          id="mc-user-name"
                          v-model="userData.username"
                          disabled
                          autocomplete="new-password"
                          :placeholder="$t('administrators.user')"
                          maxlength="75"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.firstName.description
                      "
                      :label="$t('administrators.name')"
                      label-for="mc-first-name"
                    >
                      <b-form-input
                        id="mc-first-name"
                        v-model="userData.firstName"
                        :placeholder="$t('administrators.name')"
                        autocomplete="new-password"
                        maxlength="30"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.lastName.description
                      "
                      :label="$t('administrators.lname')"
                      label-for="mc-last-name"
                    >
                      <b-form-input
                        id="mc-last-name"
                        v-model="userData.lastName"
                        autocomplete="new-password"
                        :placeholder="$t('administrators.lname')"
                        maxlength="30"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="KeyIcon" />
                <span> {{ $t('administrators.pass') }} </span>
              </template>
              <b-card-body>
                <b-card-title class="mt-1">
                  {{ $t('changPass') }}
                </b-card-title>
                <b-row>
                  <b-col md="6">
                    <!-- Password -->
                    <validation-provider
                      v-slot="validationContext"
                      name="password"
                      vid="Password"
                      rules="min:8|password"
                    >
                      <b-form-group
                        :label="$t('administrators.pass')"
                        label-for="password"
                      >
                        <b-input-group>
                          <b-form-input
                            id="password"
                            v-model="userData.password"
                            autocomplete="new-password"
                            :type="passwordFieldType"
                            :placeholder="$t('administrators.pass')"
                            :state="getValidationState(validationContext)"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{
                          checkPassword(validationContext.failedRules)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <!-- c-password -->
                    <validation-provider
                      v-slot="validationContext"
                      name="c-password"
                      vid="c-password"
                      rules="confirmed:Password"
                    >
                      <b-form-group
                        :label="$t('administrators.repass')"
                        label-for="c-password"
                      >
                        <b-input-group>
                          <b-form-input
                            id="c-password"
                            v-model="userData.cpassword"
                            autocomplete="new-password"
                            :type="passwordFieldType"
                            placeholder="$t('administrators.repass')"
                            :state="getValidationState(validationContext)"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              :icon="passwordToggleIcon"
                              class="cursor-pointer"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{
                          checkConfirmPassword(validationContext.failedRules)
                        }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="AtSignIcon" />
                <span>
                  {{ $t("administrators.contact") }}
                </span>
              </template>
              <b-card-body>
                <b-card-title class="mt-1">
                  {{ $t("administrators.contact2") }}
                </b-card-title>
                <b-row>
                  <b-col md="3">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.email.description
                      "
                      label="Email"
                      label-for="mc-user-email"
                    >
                      <validation-provider
                        v-slot="validationContext"
                        name="User Email"
                        rules="email"
                      >
                        <b-form-input
                          id="mc-user-email"
                          v-model="userData.email"
                          autocomplete="new-password"
                          :placeholder="$t('administrators.email')"
                          maxlength="254"
                          :state="getValidationState(validationContext)"
                        />
                        <small class="text-danger">{{
                          checkEmail(validationContext.failedRules)
                        }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="SettingsIcon" />
                <span> {{ $t("administrators.info") }} </span>
              </template>
              <b-card-body>
                <b-row>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.dateJoined.description
                      "
                      :label="$t('administrators.date')"
                      label-for="mc-dateJoined-name"
                    >
                      <strong>
                        {{ checkDate(userData.dateJoined) }}
                      </strong>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      v-b-tooltip.hover.bottom="introspectionData.lastLogin.description
                      "
                      :label="$t('administrators.last')"
                      label-for="mc-lastLogin-name"
                    >
                      <strong>
                        {{ checkDate(userData.lastLogin) }}
                      </strong>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-tab>
          </b-tabs>
        </b-form>
      </validation-observer>
      <b-button
        variant="success"
        :disabled="disabled"
        class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right mt-5"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="clck()"
      >
        <feather-icon icon="SaveIcon" />
        {{ $t("dataGeneric.save") }}
      </b-button>
    </b-card>
  </section>
</template>

<script>

import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import store from '@/store'
import * as constants from '@core/utils/constants'

import {
  BCard,
  BTabs,
  BTab,
  BCol,
  BRow,
  BCardTitle,
  BCardBody,
  BButton,
  BFormGroup,
  BFormInput,
  VBTooltip,
  BInputGroup,
  BInputGroupAppend,
  BForm,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, length } from '@validations'
import Ripple from 'vue-ripple-directive' // Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import selectOptions from '@/libs/utils/v-select-from-reflection'
import formValidation from '@core/comp-functions/forms/form-validation'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getUserData } from '@/auth/utils'
import i18n from '@/libs/i18n'
import userStoreModule from './administrators/administratorsStoreModule'

let forbid = false
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardTitle,
    BInputGroup,
    BInputGroupAppend,
    BCardBody,
    BTabs,
    BTab,
    BCol,
    BRow,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const simpleRules = ref(null)
    const toast = useToast()
    const userData = ref(null)
    const permissionGroups = ref(null)
    const selectedPermissionGroups = ref(null)
    const introspectionData = ref({})

    const USER_APP_STORE_MODULE_NAME = 'admin-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store
      .dispatch('admin-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => {
        userData.value = response.data.data.allProfiles.edges[0].node

        const groups = response.data.data.allGroups.edges
        const tempArray = []
        const tempObject = {}
        groups.forEach(element => {
          tempArray.push(element.node)
        })
        permissionGroups.value = tempArray
        const userGroups = response.data.data.allProfiles.edges[0].node.customuserPtr.groups
          .edges
        selectedPermissionGroups.value = userGroups[0].node

        const loginUser = getUserData()
        /* let isSponsor = false
        if (loginUser.groups.edges.length !== 0) {
          isSponsor = loginUser.groups.edges[0].node.name === constants.sponsor
        } */

        // if (isSponsor) {
        let authorized = false
        if (userData.value.id === loginUser.id) authorized = true
        if (!authorized) {
          router.push({ name: 'misc-not-authorized' })
        }
        // }

        /* eslint-disable no-unused-vars */
        Object.entries(response.data.data.allProfiles.edges[0].node).forEach(
          ([key, value2]) => {
            /* eslint no-underscore-dangle: ["error", { "allow": ["__type"] }] */
            response.data.data.__type.fields.forEach(value => {
              if (key === value.name) {
                const mvalue = value
                mvalue.value = value2
                tempObject[key] = mvalue
              }
            })
          },
        )
        introspectionData.value = tempObject
      })
      .catch(error => {
        console.log(error)
        if (error.status === 404) {
          userData.value = undefined
        }
      })

    const mutateUser = () => {
      simpleRules.value.validate().then(success => {
        let pass = true
        try {
          if (userData.value.cpassword === undefined) userData.value.cpassword = ''

          if (userData.value.password === undefined) userData.value.password = ''

          if (userData.value.cpassword === userData.value.password) pass = true
          else pass = false

          if (userData.value.cpassword === null) userData.value.password = null
        } catch (error) {
          console.log(error)
        }
        if (success && pass) {
          store
            .dispatch('admin-user/mutateUser', {
              user: userData.value,
              fields: introspectionData.value,
              groups: selectedPermissionGroups.value.id,
            })
            .then(response => {
              if (response.data.data.updateCustomuser) {
                userData.value = response.data.data.updateCustomuser.customUser
                toast({
                  component: ToastificationContent,
                  props: {
                    title: i18n.t('success'),
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                })
              } else {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: response.data.errors[0].message,
                  },
                })
              }
            })
            .catch(error => {
              console.log(error)
              console.log(error.response.data.errors[0])
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: error.response.data.errors[0].message,
                },
              })
            })
        } else {
          let i = 0
          Object.keys(simpleRules.value.$data.errors).map(key => {
            if (simpleRules.value.$data.errors[key][0]) {
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: simpleRules.value.$data.errors[key][0],
                },
              })
            }
            if (!pass && i === 0) {
              i += 1
              toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: i18n.t('confirmPassword'),
                },
              })
            }
            return key
          })
        }
      })
    }
    const blankUserData = {
      password: '',
      cpassword: '',
    }

    const userDataBlank = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userDataBlank.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const { getValidationState } = formValidation(resetuserData)
    return {
      userData,
      getValidationState,
      permissionGroups,
      selectedPermissionGroups,
      introspectionData,
      selectOptions,
      mutateUser,
      simpleRules,
    }
  },
  data() {
    return {
      email,
      forbid,

      required,
      length,
      loginUser: getUserData(),
      disabled: true,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    try {
      if (this.loginUser.groups.edges[0].node.name !== constants.administrador) {
        forbid = true
      } else {
        forbid = false
      }
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
  },
  methods: {
    checkDate(value) {
      const language = `${this.$i18n.locale}-${(this.$i18n.locale).toUpperCase()}`
      const date = new Date(value)
      const options = {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric',
      }
      const dateFormat = new Intl.DateTimeFormat(language, options)
      return dateFormat.format(date)
    },
    checkEmail(rules) {
      if (rules.email) {
        return (this.$t('verifiedEmail'))
      }
      return ''
    },
    checkPassword(rules) {
      if (rules.min) {
        return (this.$t('pass8Cha'))
      }
      if (rules.password) {
        return (this.$t('passMayus'))
      }
      return ''
    },
    checkConfirmPassword(rules) {
      if (rules.confirmed) {
        return (this.$t('confirmPassword'))
      }
      return ''
    },
    changeForm() {
      this.disabled = false
    },
    clck() {
      this.mutateUser()
      this.disabled = !this.disabled
    },
  },
}
</script>
<style scoped></style>
